import {STAY_WITH} from "../../admin/student/constant";

export const INITIAL_VALUE = {
  formChildren: {
    address: "",
    alias: "",
    birthday: "",
    busRouteFrom: '',
    busRouteTo: '',
    country: "VNM",
    fullName: "",
    gender: "",
    registerClass: ''
  },
  formLearning: {
    childLearn03: '',
    childLearn36: '',
    childLearn67: ''
  },
  formFamily: {
    stayWith: STAY_WITH.BOTH,
    fatherFullName: '',
    fatherJob: '',
    fatherBirthday: '',
    fatherGrade: '',
    fatherCurrentJob: '',
    fatherMobilePhone: '',
    fatherTablePhone: '',
    fatherEmail: '',
    motherFullName: '',
    motherJob: '',
    motherBirthday: '',
    motherGrade: '',
    motherCurrentJob: '',
    motherMobilePhone: '',
    motherTablePhone: '',
    motherEmail: '',
    numberOfChildren: ''
  },
  formChildrenHealth: {
    summaryBirthDay: '',
    crawlAge: '',
    sitAge: '',
    walkAge: '',
    speakAge: '',
    sleepAlone: '',
    families: [],
    allergy: ''
  },
  formChildSickness: {
    malnutrition: '',
    malnutritionDetail: '',
    medicineShock: '',
    medicineShockDetail: '',
    specialDiet: '',
    specialDietDetail: '',
    heartSick: '',
    heartSickDetail: '',
    respiratory: '',
    respiratoryDetail: '',
    medicineAllergy: '',
    medicineAllergyDetail: '',
    backBone: '',
    backBoneDetail: '',
    celiac: '',
    celiacDetail: '',
    down: '',
    downDetail: '',
    epileptic: '',
    epilepticDetail: '',
    diabetes: '',
    diabetesDetail: '',
    hiv: '',
    hivDetail: '',
    addHdhd: '',
    addHdhdDetail: '',
    drugReaction: '',
    drugReactionDetail: '',
    digestive: '',
    digestiveDetail: '',
    skin: '',
    skinDetail: '',
    helminths: '',
    helminthsDetail: '',
    recentlySickness: '',
    other: '',
    sickWarning: '',
    longTermMedicine: '',
    longTermMedicineDetail: ''
  },
  formOtherInformation: {
    parentNeedAboutChildren: '',
    educationSubjects: '',
    understandingWaldorf: '',
    joinConference: '',
    knownViaSocial: '',
    knownViaSocialOther: '',
    schoolVolunteer: [],
    schoolVolunteerOther: '',
    parentSharing: ''
  },
  formHomeActivity: {
    wakeupFeeling: '',
    sleepTime: '',
    wakeupTime: '',
    parentReactionAtMistake: '',
    babyHabit: '',
    specialChildrenNeed: '',
    outdoorActivity: '',
    oftenUsingSweet: '',
    extraSkillClass: '',
    extraSkillClassDetail: '',
    deviceAllowance: '',
    watchingTVFrequency: '',
    tvShow: '',
    angryReaction: '',
    usualPlaying: ''
  }
};

export const CHILD_SICKNESS_LIST = [
  {
    label: 'Suy dinh dưỡng',
    name: 'malnutrition',
    detail: 'malnutritionDetail'
  },
  {
    label: 'Sốc mẫn cảm, sốc phản vệ với thuốc',
    name: 'medicineShock',
    detail: 'medicineShockDetail'
  },
  {
    label: 'Yêu cầu ăn kiêng đặc biệt ',
    name: 'specialDiet',
    detail: 'specialDietDetail'
  },
  {
    label: 'Các vấn đề về tim mạch',
    name: 'heartSick',
    detail: 'heartSickDetail'
  },
  {
    label: 'Các vấn đề về đường hô hấp, hen suyển',
    name: 'respiratory',
    detail: 'respiratoryDetail'
  },
  {
    label: 'Các vấn đề về dị ứng (liên quan đến thuốc men)',
    name: 'medicineAllergy',
    detail: 'medicineAllergyDetail'
  },
  {
    label: 'Các vấn đề về lưng xương khớp ',
    name: 'backBone',
    detail: 'backBoneDetail'
  },
  {
    label: `Bệnh Celiac (một bệnh tiêu hoá gây tổn thương ruột non và gây cản
      trở sự hấp thụ các chất dinh dưỡng từ thức ăn)`,
    name: 'celiac',
    detail: 'celiacDetail'
  },
  {
    label: 'Hội chứng DOWN',
    name: 'down',
    detail: 'downDetail'
  },
  {
    label: 'Động kinh',
    name: 'epileptic',
    detail: 'epilepticDetail'
  },
  {
    label: 'Tiểu đường',
    name: 'diabetes',
    detail: 'diabetesDetail'
  },
  {
    label: 'HIV, viêm gan A, B, C',
    name: 'hiv',
    detail: 'hivDetail'
  },
  {
    label: 'ADD/HDHD ..., (tăng động, giảm chú ý)',
    name: 'addHdhd',
    detail: 'addHdhdDetail'
  },
  {
    label: 'Phản ứng thuốc',
    name: 'drugReaction',
    detail: 'drugReactionDetail'
  },
  {
    label: 'Bệnh về tiêu hoá',
    name: 'digestive',
    detail: 'digestiveDetail'
  },
  {
    label: 'Các bệnh ngoài da truyền nhiễm',
    name: 'skin',
    detail: 'skinDetail'
  },
  {
    label: 'Nhiễm một số loại giun sán',
    name: 'helminths',
    detail: 'helminthsDetail'
  },
  {
    label: 'Các bệnh lý mắc phải gần đây',
    name: 'longTermMedicine',
    detail: 'longTermMedicineDetail'
  },
  {
    label: 'Vui lòng đánh dấu nếu con bạn đang ở trong tình trạng <span class="badge badge-danger">cảnh báo y tế</span>',
    name: 'sickWarning'
  },
]
