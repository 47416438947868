import React from 'react';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const MainPublicPage = props => {
  return (
    <div className="container mb-5 h-100">
      <div className="text-center" style={{margin: "160px auto"}}>
        <span className="svg-icon svg-icon-primary mb-4 d-block">
          <SVG
            style={{height: 96, width: 96}}
            src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
            className="align-self-center"
          />
        </span><br/>
        <h3>Chúng tôi đang xây dựng website, vui lòng liên hệ qua email&nbsp;
          <a
            href="mailto:tieuhocwaldorfdanang@gmail.com"
            target="_blank">tieuhocwaldorfdanang@gmail.com</a>
        </h3></div>
    </div>
  );
};

MainPublicPage.propTypes = {};

export default MainPublicPage;
