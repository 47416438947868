import {format, parse, subDays, subMonths, subYears, isDate as isDateFNS} from "date-fns";

export const isDate = (d) =>{
  return d instanceof Date && !isNaN(d);
};
export const FNS_DATE_TIME_FORMAT = "dd/MM/yyyy HH:mm:ss";
export const FNS_DATE_FORMAT = "dd/MM/yyyy";
export const FNS_MONTH_FORMAT = "MM/yyyy";
export const TIME_UNIT = {
  DAY: "d",
  YEAR: "y",
  MONTH: "m"
};

export function formatDate(dateObj) {
  if (dateObj) {
    return format(dateObj, FNS_DATE_TIME_FORMAT);
  }
  return ''
}

export function formatDateOnly(dateObj) {
  if (dateObj) {
    return format(dateObj, FNS_DATE_FORMAT);
  }
  return ''
}

export function parseDateOnly(dateString) {
  return parse(dateString, FNS_DATE_FORMAT);
}

export function formatMonth(month, year) {
  return format(new Date(year, month - 1, 1, 0, 0, 0, 0), FNS_MONTH_FORMAT);
}

export function lastDate(quantity, dateUnit) {
  switch (dateUnit) {
    case TIME_UNIT.DAY:
      return subDays(new Date(), quantity);
    case TIME_UNIT.YEAR:
      return subYears(new Date(), quantity);
    case TIME_UNIT.MONTH:
      return subMonths(new Date(), quantity);
    default:
      return new Date();
  }
}

export function formatFirebaseDate(dateObj, isDateOnly = false) {
  if (!dateObj) {
    return ''
  }
  if (typeof dateObj === 'string') {
    return ''
  }
  return isDateOnly ? formatDateOnly(dateObj.toDate()) : formatDate(dateObj.toDate())
}
