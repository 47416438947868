import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import FormErrorMessage from "./FormErrorMessage";
import FormGroup from "./FormGroup";

const FormGroupInput = ({
                          name,
                          label,
                          register,
                          type,
                          error,
                          placeholder,
                          fieldRequired,
                          ...props
                        }) => {
  const {onChange, onBlur, name: _name, ref} = register(name);
  return (
    <FormGroup
      label={label}
      forId={_name}
      fieldRequired={fieldRequired}
    >
      <Input
        type={type}
        id={name}
        innerRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={_name}
        invalid={!!error}
        placeholder={placeholder}
        {...props}
      />
      <FormErrorMessage error={error}/>
    </FormGroup>
  );
};

FormGroupInput.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "email",
    "select",
    "file",
    "radio",
    "checkbox",
    "textarea",
    "button",
    "reset",
    "submit",
    "date",
    "datetime-local",
    "hidden",
    "image",
    "month",
    "number",
    "range",
    "search",
    "tel",
    "url",
    "week",
    "password",
    "datetime",
    "time",
    "color"
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  register: PropTypes.any.isRequired,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  fieldRequired: PropTypes.bool
};

export default FormGroupInput;
