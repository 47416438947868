import React, {useEffect, useRef, useState} from "react";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import {firebaseAuthChange} from "../../../libs/service/Firebase";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const {authToken} = useSelector(
    ({auth}) => ({
      authToken: auth.authToken
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      let unsubscribe = () => {
      }
      if (!didRequest.current) {
        unsubscribe = firebaseAuthChange((user) => {
          console.log('Got user already login', user)
          if (user) {
            dispatch(props.fulfillUser(user));
          } else {
            dispatch(props.fulfillUser(user));
          }
          setShowSplashScreen(false);
        })
      }

      return () => {
        didRequest.current = true;
        unsubscribe()
      }
    };

    if (authToken) {
      requestUser().then();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen/> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
