import React, { useCallback } from 'react';
import { useFormik } from "formik";
import { INITIAL_VALUE } from "../constant";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import PropTypes from "prop-types";
import { Controller, useForm } from 'react-hook-form';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import { Col, FormGroup } from 'reactstrap';
import { KNOW_VIA_SOCIAL } from '../../../admin/student/constant';
import InputRadio from '../../../../components/input/InputRadio';

const FormOtherInformation = ({ onNext, ...props }) => {
  const formik = useFormik({
    initialValues: INITIAL_VALUE.formOtherInformation,
    onSubmit: (values) => {
      props.onNext(values);
    }
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: props.data || INITIAL_VALUE.formOtherInformation
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  return (
    <form onSubmit={onSubmit}>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="parentNeedAboutChildren" type="textarea" register={register} label="Phụ huynh mong muốn điều gì khi gởi con đến trường ?" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="educationSubjects" type="textarea" register={register} label="Những chủ đề giáo dục mà phụ huynh quan tâm" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="understandingWaldorf" type="textarea" register={register} label="Phụ huynh hiểu thế nào về phương pháp giáo dục Waldorf-Steiner" />
        </div>
      </div>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Phụ huynh có muốn tham gia các hội thảo về giáo dục do nhà trường tổ chức ?
          </label>
        </Col>
        <Col md={8}>
          <div className="radio-inline">
            <InputRadio value={'0'} label={'Không'} name={'joinConference'} register={register} />
            <InputRadio value={'1'} label={'Có'} name={'joinConference'} register={register} />
          </div>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Phụ huynh biết về trường qua ?
          </label>
        </Col>
        <Col md={8}>
          <div className="radio-inline">
            {
              KNOW_VIA_SOCIAL.map((t, i) => <InputRadio key={`knownViaSocial${i}`} value={`${i}`} label={t} name={'knownViaSocial'} register={register} />)
            }
          </div>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label">
            Phụ huynh có thể tình nguyện đóng góp thời gian và chuyên môn của mình vào các hoạt động của nhà trường
            (vui lòng nhập thêm hoạt động phù hợp với phụ huynh):
          </label>
        </Col>
        <Col md={8}>
          <Controller
            name="schoolVolunteer"
            id="schoolVolunteer"
            control={control}
            render={({ field: { value, onChange, name, onBlur } }) => {
              return (
                <CreatableSelect
                  name={name}
                  onBlur={onBlur}
                  isMulti
                  value={value ? value.map(v => ({ value: v, label: v })) : null}
                  onChange={val => onChange(val ? val.map(t => t.value) : null)}
                  placeholder={'Chọn hoặc nhập hoạt động mới...'}
                  options={[
                    { value: 'Dịch tài liệu', label: 'Dịch tài liệu' },
                    { value: 'Chăm sóc cảnh quan', label: 'Chăm sóc cảnh quan' },
                    { value: 'Làm đồ gỗ', label: 'Làm đồ gỗ' },
                    { value: 'Đại diện ban phụ huynh', label: 'Đại diện ban phụ huynh' }
                  ]}>
                </CreatableSelect>
              )
            }}
          />
        </Col>
      </FormGroup>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="parentSharing" type="textarea" register={register} label="Một số thông tin khác phụ huynh muốn chia sẻ thêm ?" />
        </div>
      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            props.onPrev && <button type="button" onClick={props.onPrev}
              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  );
};

FormOtherInformation.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormOtherInformation;
