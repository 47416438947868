import { useCallback, useState } from "react";

export const API_STATE = {
  NONE: "NONE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL"
};

export const useApi = asyncApi => {
  const [state, setState] = useState({
    isLoading: false,
    state: API_STATE.NONE,
    errors: [],
    resp: null
  });

  const exec = useCallback(
    (...args) => {
      setState(previous => ({
        ...previous,
        isLoading: true,
        state: API_STATE.LOADING
      }));
      return asyncApi(...args).then(
        t => {
          setState({
            errors: [],
            resp: t,
            isLoading: false,
            state: API_STATE.SUCCESS
          });
          return t;
        },
        err => {
          let errors = [];
          if (err) {
            if (err.error) {
              errors.push({ message: err.error });
            } else if (err.errors) {
              errors = err.errors;
            } else if(err.message){
              errors.push({ message: err.message });
            } else {
              errors.push({ message: err});
            }
          }

          setState({
            state: API_STATE.FAIL,
            errors: errors,
            resp: null,
            isLoading: false
          });
        }
      );
    },
    [asyncApi]
  );

  return { state, exec };
};
