import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import {FNS_DATE_FORMAT} from "../../utils/date.util";
import {isFunc} from "../../utils/util";

const InputCalendar = forwardRef(({onClick, className, ...props}, ref) => (
  <div className='input-group'>
    <input type="text" className={classNames("form-control", className)} {...props} ref={ref}/>
    <div className="input-group-append">
      <button className="btn btn-outline-secondary" type="button" onClick={onClick}><i
        className="fa fa-calendar-alt"/></button>
    </div>
  </div>
));

const DateSelect = (
  {
    onChange,
    value,
    invalid,
    onBlur,
    placeholder,
    openToDate,
    className,
    ...
      props
  }
) => {
  const handleDayChange = selectedDay => {
    console.log(selectedDay);
    if (onChange && isFunc(onChange)) {
      onChange(selectedDay);
    }
  };

  const handleOnBlur = ({target: {val}}) => {
    console.log(val);
    if (onBlur && isFunc(onBlur)) {
      onBlur();
    }
  };

  return (
    <DatePicker
      className={classNames(className, {
        "is-invalid": !!invalid
      })}
      {...props}
      customInput={<InputCalendar/>}
      onBlur={handleOnBlur}
      selected={value}
      onChange={handleDayChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      placeholderText={placeholder}
      dateFormat={FNS_DATE_FORMAT}
      openToDate={openToDate}
    />
  );
};

DateSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  invalid: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  openToDate: PropTypes.instanceOf(Date)
}
;

export default DateSelect;
