import React from 'react';

const PublicFooter = props => {
  return (
    <footer className="p-4">
      <div className="inner text-center"><p className="lead mb-4">
        <strong>HARMONY WALDORF - INSPIRED HOMESCHOOLING OF ĐÀ NẴNG</strong></p>
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="row justify-content-center">
              <div className="col-md-5 text-md-left">
                <p className="text-nowrap">
                  <i className="fa fa-mobile-alt fa-fw pr-4"/>
                  <span><a href="tel:0918145516"><strong>091 8145516</strong></a></span>
                </p>
                <p className="text-nowrap"><i className="fa fa-envelope fa-fw pr-4"/>
                  <span><a href="mailto: tieuhocwaldorfdanang@gmail.com">tieuhocwaldorfdanang@gmail.com</a></span>
                </p>
              </div>
              <div className="col-md-5 text-md-left">
                <p className="text-truncate">
                  <i className="fa fa-map-marker-alt fa-fw pr-4"/>
                  <span><a href="https://maps.app.goo.gl/ya5qZeRSN98owqTx6" target="_blank">Hoà Phong, Hòa Vang, Đà Nẵng, Vietnam</a></span>
                </p>
                <p className="text-nowrap">
                  <i className="fab fa-facebook-f fa-fw pr-4"/>
                  <span><a href="https://facebook.com/HarmonyWaldorfDanangSchool"
                           target="_blank">HarmonyWaldorfDanangSchool</a></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

PublicFooter.propTypes = {};

export default PublicFooter;
