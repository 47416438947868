import React from "react";
import PropTypes from "prop-types";
import {FormGroup as RFormGroup, Label} from "reactstrap";

const FormGroup = ({
                     forId,
                     fieldRequired,
                     label,
                     children
                   }) => {
  return (
    <RFormGroup>
      <Label
        for={forId}
        aria-required={fieldRequired}
        // className="text-nowrap"  
        className=""
      >
        {label}
        {fieldRequired ? <span className="text-danger">&nbsp;*</span> : null}
      </Label>
      {children}
    </RFormGroup>
  );
};

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  fieldRequired: PropTypes.bool,
  forId: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default FormGroup;
