import {ADMIN_URL} from "../../../constant";

export const STUDENT_URL = `${ADMIN_URL}/student`

export const BUS_ROUTE = [
  'Công viên 29/3',
  'Sân vận động đĩa bay',
  'Phụ huynh tự đưa đón'
]

export const CLASS_LIST = ['Danh sách chờ (khi bé của bạn nhỏ hơn 5 tuổi)', 'Tiền tiểu học', 'Lớp 1', 'Lớp 2', 'Lớp 3', 'Lớp 4', 'Lớp 5', 'Lớp 6', 'Lớp 7', 'Lớp 8'];

export const STAY_WITH = {
  BOTH: 0,
  FATHER: 1,
  MOTHER: 2
}

export const STAY_WITH_LABEL = [
  'Bé ở cùng ba & mẹ',
  'Bé ở với ba',
  'Bé ở với mẹ'
]

export const GRADE = [
  'Cao đẳng', 'Đại học', 'Sau đại học', 'Khác'
]

export const GENDER = {
  MALE: 'Nam',
  FEMALE: 'Nữ'
}

export const ANSWER_OPTION = {
  NO: '0',
  YES: '1'
}
export const ANSWER = ['Không', 'Có']
export const ANSWER_CSS = ['badge badge-success', 'badge badge-danger']

export const FAMILIES = ['Ông', 'Bà', 'Cô', 'Dì', 'Chú', 'Bác', 'Người giúp việc'];

export const WATCHING_TV_FREQUENCY = [
  'Mỗi tối',
  'Thỉnh thoảng'
];

export const TV_SHOW = [
  'Ca nhạc',
  'Phim hoạt hình siêu nhân',
  'Các chương trình khác'
];

export const OFTEN_USING_SWEET = [
  'Không',
  'Thỉnh Thoảng',
  'Có'
];

export const OFTEN_USING_SWEET_CSS = [
  'badge badge-success',
  'badge badge-warning',
  'badge badge-warning'
];

export const KNOW_VIA_SOCIAL = [
  'Truyền miệng',
  'Facebook',
  'Website của trường',
  'Báo chí',
  'Khác'
]

export const ANGRY_REACTION = ['La hét', 'Đánh', 'Cắn', 'Khóc']
