import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import {INITIAL_VALUE} from "../constant";
import { useForm } from 'react-hook-form';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import { yupResolver } from '@hookform/resolvers/yup';

const FormLearningSchema = Yup.object().shape({
  childLearn03: Yup.string(),
  childLearn36: Yup.string(),
  childLearn67: Yup.string()
});

const FormLearning = ({ onNext, onPrev, ...props}) => {

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(FormLearningSchema),
    defaultValues: props.data || INITIAL_VALUE.formChildren
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group row">
        <div className="col-md-12">
          <FormGroupInput name="childLearn03" type="textarea" register={register} label="Bố mẹ kể lại vắn tắt quá trình học của trẻ từ 0 - 3 tuổi" />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-md-12">
          <FormGroupInput name="childLearn36" type="textarea" register={register} label="Bố mẹ kể lại vắn tắt quá trình học của trẻ từ 3 - 6 tuổi" />
        </div>

      </div>

      <div className="form-group row">
        <div className="col-md-12">
          <FormGroupInput name="childLearn67" type="textarea" register={register} label="Bố mẹ kể lại vắn tắt quá trình học của trẻ từ 6 - 7 tuổi" />
        </div>

      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
                                    className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  )
};

FormLearning.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormLearning;
