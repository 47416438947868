import React from 'react';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

const AboutUs = props => {
  return (
    <div className="p-4">
      <div className="container mb-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-7">
            <h1 className="display-3 mb-4">Triết lý</h1>
            <p>Trường (Harmony Đà Nẵng) lấy cảm hứng từ giáo dục Waldorf, một mô hình
              giáo dục dựa trên triết lý của nhà khoa học và nhà tư tưởng Rudolf Steiner (1861-1925). Triết lý có những
              nghiên cứu, phân tích sâu sắc và toàn thể bản chất của con người và các giai đoạn phát triển của con người
              từ thơ ấu cho đến lúc trưởng thành. Theo đó, đối với giai đoạn tiểu học, tương ứng với sự phát triển của
              trẻ từ lúc thay răng cho đến thời điểm dậy thì, trẻ cần được tập trung nuôi dưỡng cảm xúc lành mạnh, phát
              triển ý chí mạnh mẽ và tiếp tục xây dựng nền tảng đúng đắn cho tư duy; đó là sự gắn kết hài hòa giữa trái
              tim (heart-feeling) với bàn tay (hands-willing) và khối óc (head-thinking) của con người. Vì vậy, chương
              trình giảng dạy của Trường được thiết kế hài hoà để mang đến những môn học và hoạt động phù hợp với sự
              phát triển của trẻ ở lứa tuổi tiểu học. </p>
          </div>
          <div className="col-md-5 text-center">
            <img alt="Responsive image"
                 className="img-thumbnail rounded"
                 src={toAbsoluteUrl("/media/about-us/phylosophy.png")}
                 style={{width: '400px'}}/></div>
        </div>
      </div>
      <div className="container" style={{marginBottom: '60px', marginTop: '60px'}}>
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5 text-center">
            <img alt="Responsive image"
                 className="img-thumbnail rounded"
                 src={toAbsoluteUrl("/media/about-us/children.jpeg")}
                 style={{width: '450px'}}/></div>
          <div className="col-md-7">
            <h1 className="display-3 mb-4">Sứ mệnh</h1>
            <p> Sứ mệnh của chúng tôi là nuôi dưỡng tình yêu thương và tự do trong trẻ,
              giúp trẻ xây dựng và phát triển các năng lực thể chất, ý chí, cảm xúc, trí tuệ và tinh thần, để trẻ có thể
              tự khám phá, nghiên cứu, thích nghi, cân bằng với cuộc sống đa dạng. </p>
          </div>
        </div>
      </div>
      <div className="container" style={{marginBottom: '60px', marginTop: '60px'}}><h1
        className="display-3 mb-4">Giá trị cốt lõi</h1>
        <div className="row row-cols-1 row-cols-md-3">
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Tôn kính</h2>
                </div>
                <p className="card-text">Chúng tôi tôn kính tuổi thơ của trẻ,
                  xem đó là nền tảng vô cùng quan trọng của một cuộc sống tốt đẹp và tự do sau này. Chúng tôi
                  tôn kính mỗi đứa trẻ, bao gồm cả sự độc đáo và khác biệt của chúng. Chúng tôi tôn kính đồng
                  nghiệp. Chúng tôi tôn kính thế giới tự nhiên và thế giới tinh thần.</p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Toàn thể</h2>
                </div>
                <p className="card-text">Chúng tôi theo đuổi sự giáo dục toàn
                  diện về thể chất, tâm trí và tinh thần cho trẻ. Chúng tôi xem mỗi đứa trẻ là một thế giới đặc
                  biệt với vô vàn điều kì diệu, và thế giới của mỗi đứa trẻ đều có ẩn chứa những tiềm năng to
                  lớn bên trong cần được quan tâm và nuôi dưỡng.</p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Chia sẻ trách nhiệm</h2>
                </div>
                <p className="card-text">Chúng tôi tin tưởng vào việc chia sẻ
                  trách nhiệm tập thể của Hội đồng giáo viên, Hội đồng điều hành và Hội đồng phụ huynh dựa trên
                  tinh thần hợp tác, minh bạch và giao tiếp cởi mở. Chúng tôi chia sẻ trách nhiệm lẫn nhau với
                  các phụ huynh để cùng đồng lòng trong việc giáo dục trẻ. Chúng tôi chia sẻ trách nhiệm xã hội
                  và đạo đức bằng việc góp phần làm cho đất nước này và thế giới này trở nên tốt đẹp hơn.</p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Cộng đồng trường học</h2>
                </div>
                <p className="card-text">Không chỉ là trường học, đó là một
                  cộng đồng được xây dựng nên bằng sự đóng góp vật chất và tinh thần, tâm sức và trí tuệ, sự
                  thấu hiểu và lòng nhân ái của cả giáo viên, học sinh, phụ huynh, nhân viên. Cộng đồng chúng
                  tôi là nơi tụ họp để nuôi dưỡng tâm trí, cơ thể và tinh thần, để truyền cảm hứng cho hạnh phúc
                  cá nhân và xã hội.</p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Tưởng tượng và sáng tạo</h2>
                </div>
                <p className="card-text">Chúng tôi tin tưởng một cách sâu sắc
                  rằng, trong lứa tuổi tiểu học, óc tưởng tượng của trẻ là vô cùng quan trọng và là nền tảng cho
                  sự sáng tạo của trẻ sau này. Chúng tôi nuôi dưỡng các năng lực này thông qua nghệ thuật, âm
                  nhạc và chuyển động.</p>
              </div>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card h-100">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="text-primary">Yêu thương và tự do</h2>
                </div>
                <p className="card-text">Chúng tôi cực kỳ xem trọng và tin
                  tưởng việc nuôi dưỡng tình yêu thương trong trẻ thông qua giáo dục tâm hồn hướng chân – thiện
                  – mỹ và trực giác nhạy bén. Chúng tôi nuôi dưỡng tình yêu thương cao cả sâu bên trong trẻ.
                  Chúng tôi nuôi dưỡng tinh thần và ý chí tự do trong trẻ, tự do gắn liền với đạo đức, tạo
                  nên những đứa trẻ mang trong lòng thôi thúc đi tìm tự do và yêu thương của chính trẻ sau
                  này.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{marginBottom: '60px'}}><h1
        className="display-3 mb-4">Chúng tôi </h1><p> Là những phụ huynh,
        những người bố, người mẹ, những người bạn, những con người đến từ nhiều lĩnh vực khác nhau, những con
        người tìm một môi trường giáo dục phù hợp với con trẻ. Và trên con đường ấy, chúng tôi đã gặp
        nhau, cùng nhau chia sẻ, cùng nhìn về một hướng và đều mong muốn làm một điều gì đó để có
        thể mang nền giáo dục Waldorf đến với nhiều gia đình hơn nữa. Do đó, chúng tôi đã cùng nhau bắt
        đầu ngôi trường này. </p></div>
    </div>
  );
};

AboutUs.propTypes = {};

export default AboutUs;
