import React, { useCallback } from 'react';
import { INITIAL_VALUE } from "../constant";
import { FAMILIES } from "../../../admin/student/constant";
import PropTypes from "prop-types";
import FormRow from '../../../../components/form/FormRow';
import { useForm } from 'react-hook-form';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import FormGroup from '../../../../components/form/FormGroup';
import { InputRadio } from '../../../../components/input/InputRadio';

const FormChildrenHealth = ({ onNext, onPrev, ...props }) => {

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: props.data || INITIAL_VALUE.formChildrenHealth
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-12">
          <FormGroupInput name="summaryBirthDay" type="textarea" register={register} label="Bố mẹ kể lại vắn tắt quá trình mang thai và sinh em bé" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <label>Độ tuổi (theo tháng) mà bé</label>
          <div className="row">
            <div className="col-md-3">
              <FormRow label="Ngồi" labelCol="auto">
                <input
                  type="number"
                  className={`form-control`}
                  name="sitAge"
                  {...register('sitAge')}
                />
              </FormRow>
            </div>
            <div className="col-md-3">
              <FormRow label="Bò" labelCol="auto">
                <input
                  type="number"
                  className={`form-control`}
                  name="crawlAge"
                  {...register('crawlAge')}
                />
              </FormRow>
            </div>
            <div className="col-md-3">
              <FormRow label="Đi" labelCol="auto">
                <input
                  type="number"
                  className={`form-control`}
                  name="walkAge"
                  {...register('walkAge')}
                />
              </FormRow>
            </div>
            <div className="col-md-3">
              <FormRow label="Nói" labelCol="auto">
                <input
                  type="number"
                  className={`form-control`}
                  name="speakAge"
                  {...register('speakAge')}
                />
              </FormRow>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroup label={'Bé đã ngủ trên giường một mình chưa ?'}>
            <div className="radio-inline">
              <InputRadio value={0} label={'Không'} name={'sleepAlone'} register={register} />
              <InputRadio value={1} label={'Có'} name={'sleepAlone'} register={register} />
            </div>
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroup label="Những người thân trong gia đình thường xuyên tiếp xúc với trẻ">
            <div className="checkbox-inline">
              {
                FAMILIES.map(t => <label className="checkbox" key={t}>
                  <input
                    type="checkbox"
                    value={t}
                    {...register('families')}
                  />
                  <span/>{t}
                </label>)
              }
            </div>
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput name="allergy" type="textarea" register={register} label="Bé có dị ứng gì không, cụ thể ?" />
        </div>
      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  )
};

FormChildrenHealth.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormChildrenHealth;
