import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {googleSignIn} from "../../../libs/service/Firebase";
import {toast} from "react-toastify";
import {API_STATE, useApi} from "../../../libs/hooks/useApi";

function Login(props) {
  const {intl} = props;
  const {state, exec} = useApi(googleSignIn)

  useEffect(() => {
    if (state.state === API_STATE.SUCCESS) {
      console.log(state.resp)
      props.login(state.resp.token)
    } else if (state.state === API_STATE.FAIL) {
      toast.error(state.errors.map(t => t.message).join('\n'))
    }
  }, [state])

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE"/>
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
        <button
          disabled={state.state === API_STATE.LOADING}
          type="button"
          className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
          onClick={() => {
            exec()
          }}>
            <span className="svg-icon svg-icon-md">
              <img src={toAbsoluteUrl("/media/svg/social-icons/google.svg")} alt="google signin"/>
            </span>
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
