import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import DateSelect from "../../../../components/date/DateSelect";
import {INITIAL_VALUE} from "../constant";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormGroupInput from "../../../../components/form/FormGroupInput";
import FormGroup from "../../../../components/form/FormGroup";
import classNames from "classnames";
import FormErrorMessage from "../../../../components/form/FormErrorMessage";
import {isDate, lastDate, TIME_UNIT} from "../../../../utils/date.util";
import Select from "react-select";
import {COUNTRY_OPTIONS} from "../../../../libs/api/country.api";
import FormRow from "../../../../components/form/FormRow";
import {BUS_ROUTE, CLASS_LIST} from "../../../admin/student/constant";

const FormChildrenSchema = Yup.object().shape({
  fullName: Yup.string().required('Họ và tên là bắt buộc.'),
  gender: Yup.string().required(),
  birthday: Yup.date().transform((value, originalValue) => {
    console.log(value, originalValue)
    if(!isDate(value)){
      return null;
    }
    return value
  }).nullable().required('Ngày Sinh là bắt buộc.'),
  country: Yup.string().required(),
  address: Yup.string().required('Địa chị là bắt buộc.'),
  registerClass: Yup.string().required('Lớp là bắt buộc.'),
  busRouteFrom: Yup.string().required('Tuyến đường là bắt buộc.'),
  busRouteTo: Yup.string().required('Tuyến đường là bắt buộc.')
});

const FormChildren = ({onNext, onPrev, ...props}) => {
  console.log(props)
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: {errors},
    watch
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(FormChildrenSchema),
    defaultValues: props.data || INITIAL_VALUE.formChildren
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6">
          <FormGroupInput name="fullName" placeholder={"Họ và Tên"}
            error={errors.fullName} label="Họ và Tên" fieldRequired={true}
            type={'text'} register={register} />
        </div>
        <div className="col-md-6">
          <FormGroupInput name="alias" placeholder={"Tên thân mật"}
            error={errors.alias} label="Tên thân mật"
            type={'text'} register={register} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label="Giới tính" fieldRequired={true} forId="gender">
            <select
              className={classNames(`form-control`, {'is-invalid': !!errors.gender})}
              id="gender"
              {...register("gender")}>
              <option value="">- Chọn giới tính -</option>
              <option value="MALE">Nam</option>
              <option value="FEMALE">Nữ</option>
            </select>
            <FormErrorMessage error={errors.gender}/>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label="Ngày sinh" fieldRequired forId="birthday">
            <Controller
              name="birthday"
              control={control}
              render={({field: {value, onChange, name, onBlur}, fieldState: {invalid, error}}) => (
                <div className={classNames({'is-invalid': invalid})}>
                  <DateSelect
                    value={value}
                    name={name}
                    placeholder="Input Birthday"
                    invalid={invalid}
                    onChange={onChange}
                    onBlur={onBlur}
                    openToDate={lastDate(18, TIME_UNIT.YEAR)}
                  />
                </div>
              )}
            />
            <FormErrorMessage error={errors.birthday}/>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <FormGroupInput
            name="address"
            fieldRequired={true}
            placeholder="Địa Chỉ"
            type="text" register={register} error={errors.address} label="Địa Chỉ"/>
        </div>
        <div className="col-md-4">
          <FormGroup label="Quốc Tịch" fieldRequired forId="country">
            <Controller
              name="country"
              id="country"
              control={control}
              render={({field: {value, onChange, name, onBlur}}) => {
                return (
                  <Select
                    placeholder="Chọn quốc tịch"
                    name={name}
                    onBlur={onBlur}
                    options={COUNTRY_OPTIONS}
                    onChange={val => onChange(val?.value)}
                    value={value ? COUNTRY_OPTIONS.find(t => t.value === value) : null}
                  />
                )
              }}
            />
            <FormErrorMessage error={errors.country}/>
          </FormGroup>
        </div>
      </div>
      <FormRow label={"Đăng ký vào lớp"} fieldRequired={true} labelCol={'auto'}>
        <select
          name="registerClass"
          className={classNames('form-control', {'is-invalid': !!errors.registerClass})}
          {...register("registerClass")}>
          <option value="">- Chọn lớp -</option>
          {CLASS_LIST.map((t, i) => <option key={`class${i}`} value={i}>{t}</option>)}
        </select>
        <FormErrorMessage error={errors.registerClass}/>
      </FormRow>
      <label className="mb-2">Tuyến đường đưa đón</label>
      <div className="row">
        <div className="col-md-6">
          <FormRow label="Buổi sáng tại" fieldRequired={true} labelCol="auto">
            <select className={classNames('form-control', {'is-invalid': !!errors.busRouteFrom})} {...register('busRouteFrom')}>
              <option value="">- Chọn tuyến đường -</option>
              {BUS_ROUTE.map((t, i) => <option key={`from${i}`} value={i}>{t}</option>)}
            </select>
            <FormErrorMessage error={errors.busRouteFrom}/>
          </FormRow>
        </div>
        <div className="col-md-6">
          <FormRow label="Buổi chiều về" fieldRequired={true} labelCol="auto">
            <select className={classNames('form-control', {'is-invalid': !!errors.busRouteTo})} {...register('busRouteTo')}>
              <option value="">- Chọn tuyến đường -</option>
              {BUS_ROUTE.map((t, i) => <option key={`to${i}`} value={i}>{t}</option>)}
            </select>
            <FormErrorMessage error={errors.busRouteTo}/>
          </FormRow>
        </div>
      </div>
      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
                                    className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  );
};

FormChildren.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormChildren;
