import React from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label } from "reactstrap";

const FormRow = ({
  labelCol = 2,
  valueCol,
  forId,
  fieldRequired,
  label,
  children
}) => {
  return (
    <FormGroup row>
      <Label
        sm={labelCol}
        for={forId}
        aria-required={fieldRequired}
        className="text-nowrap"
      >
        {label}
        {fieldRequired ? <span className="text-danger">*</span> : null}
      </Label>
      <Col sm={valueCol}>{children}</Col>
    </FormGroup>
  );
};

FormRow.propTypes = {
  labelCol: PropTypes.any,
  valueCol: PropTypes.any,
  label: PropTypes.string.isRequired,
  fieldRequired: PropTypes.bool,
  forId: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default FormRow;
