/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {AuthInit} from "./modules/Auth";
import {Routes} from "./Routes";
import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ADMIN_URL, AUTH_URL} from "./constant";
import PublicPage from "./PublicPage";

export default function App({store, persistor, basename}) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen/>}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                <ToastContainer
                  position="bottom-left"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {/* Render routes with provided `Layout`. */}
                <Switch>
                  <Route path={[ADMIN_URL, AUTH_URL]}>
                    <AuthInit>
                      <Routes/>
                    </AuthInit>
                  </Route>
                  <Route path="/" component={PublicPage}/>
                </Switch>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
