import React, { useCallback } from 'react';
import * as Yup from "yup";
import { CHILD_SICKNESS_LIST, INITIAL_VALUE } from "../constant";
import PropTypes from "prop-types";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputRadio } from '../../../../components/input/InputRadio';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import { Col, FormGroup } from 'reactstrap';

const FormChildSicknessSchema = Yup.object().shape({
  malnutrition: Yup.string().required(),
  malnutritionDetail: '',
  medicineShock: Yup.string().required(),
  medicineShockDetail: '',
  specialDiet: Yup.string().required(),
  specialDietDetail: '',
  heartSick: Yup.string().required(),
  heartSickDetail: '',
  respiratory: Yup.string().required(),
  respiratoryDetail: '',
  medicineAllergy: Yup.string().required(),
  medicineAllergyDetail: '',
  backBone: Yup.string().required(),
  backBoneDetail: '',
  celiac: Yup.string().required(),
  celiacDetail: '',
  down: Yup.string().required(),
  downDetail: '',
  epileptic: Yup.string().required(),
  epilepticDetail: '',
  diabetes: Yup.string().required(),
  diabetesDetail: '',
  hiv: Yup.string().required(),
  hivDetail: '',
  addHdhd: Yup.string().required(),
  addHdhdDetail: '',
  drugReaction: Yup.string().required(),
  drugReactionDetail: '',
  digestive: Yup.string().required(),
  digestiveDetail: '',
  skin: Yup.string().required(),
  skinDetail: '',
  helminths: Yup.string().required(),
  helminthsDetail: '',
  recentlySickness: '',
  other: '',
  sickWarning: Yup.string().required(),
  longTermMedicine: Yup.string().required(),
  longTermMedicineDetail: ''
});

const FormChildSickness = ({ onNext, onPrev, ...props }) => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: props.data || INITIAL_VALUE.formChildSickness,
    resolver: yupResolver(FormChildSicknessSchema),
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  const watcher = watch();

  return (
    <form onSubmit={onSubmit}>
      <p className="text-danger">Vui lòng điền thông tin chi tiết về các vấn đề về sức khoẻ mà nhà trường cần phải biết, bao gồm
        bất kỳ loại thuốc nào mà học sinh mang theo uống mỗi ngày</p>
      {
        CHILD_SICKNESS_LIST.map(t => (
          <FormGroup key={t.name} row>
            <Col md={4}>
              <label className="form-label col-form-label"><span dangerouslySetInnerHTML={{ __html: t.label }}/>&nbsp;<span className="text-danger">&nbsp;*</span></label>
            </Col>
            <Col md={3}>
              <div className="radio-inline">
                <InputRadio value={'0'} label={'Không'} name={t.name} register={register} />
                <InputRadio value={'1'} label={'Có'} name={t.name} register={register} />
              </div>
            </Col>
            {
              (Number(watcher[t.name]) === 1 && t.detail) &&
              <Col md={5}>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder='Chi tiết'
                  name={t.detail}
                  {...register(t.detail)}
                />
              </Col>
            }
          </FormGroup>
        ))
      }

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={4} name="recentlySickness" type="textarea" register={register} label="Các bệnh lý mắc phải gần đây" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={4} name="other" type="textarea" register={register} label="Thông tin khác" />
        </div>
      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  );

};

FormChildSickness.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormChildSickness;
