import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {Link, NavLink} from "react-router-dom";
import './public.scss'
import {Collapse} from "reactstrap";

const PublicHeader = props => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <nav
      className="navbar navbar-expand-sm navbar-dark bg-hawis p-3 px-md-4 border-bottom shadow-sm">
      <div className="container-fluid">
        <Link className="mb-0" to="/">
          <img src={toAbsoluteUrl('/media/logos/full_harmony.png')} alt="Logo" style={{width: '200px'}}/>
        </Link>
        <button aria-expanded="false" aria-label="Toggle navigation"
                onClick={toggleNavbar}
                className="navbar-toggler float-right" type="button">
          <span className="navbar-toggler-icon"/>
        </button>
        <Collapse isOpen={!collapsed} navbar>
          <ul className="navbar-nav ml-auto ">
            <li className="nav-item"> <NavLink to="/dang-ky-tre" activeClassName="active" className="nav-link kt-link">
                Đăng Ký Trẻ
              </NavLink></li>
            <li className="nav-item">
              <NavLink to="/about-us" activeClassName="active" className="nav-link kt-link">
                Về Harmony
              </NavLink>
            </li>
          </ul>
        </Collapse>
      </div>
    </nav>
  );
};

PublicHeader.propTypes = {

};

export default PublicHeader;
