import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {addDoc, collection, doc, getDoc, deleteDoc, getDocs, getFirestore, limit, orderBy, query, where, startAfter} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyACMbgPP7zpCcbP8qnvPBRrNWSxuwzH178",
  authDomain: "edu3h-3d22e.firebaseapp.com",
  databaseURL: "https://edu3h-3d22e.firebaseio.com",
  projectId: "edu3h-3d22e",
  storageBucket: "edu3h-3d22e.appspot.com",
  messagingSenderId: "550062584091",
  appId: "1:550062584091:web:5f56837a95363a905163be",
  measurementId: "G-Z5S755NHBB"
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth();
export const googleSignIn = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(firebaseAuth, provider).then(async (result) => {
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    const admin = await getAdmin(result.user.email)
    if (!admin.exists()) {
      throw new Error('You don\'t have permission to access this page')
    }
    return {
      user, token
    }
  })
}
export const firebaseAuthChange = (callback) => onAuthStateChanged(firebaseAuth, callback)
const db = getFirestore()

export function getAdmin(email) {
  const docRef = doc(db, "admin", email);
  return getDoc(docRef);
}

export async function searchStudent(size, lastItem) {
  let q;
  console.log(size, lastItem)
  if (lastItem) {
    q = query(collection(db, "register"), orderBy("createdDate", "desc"), startAfter(lastItem), limit(size))
  } else {
    q = query(collection(db, "register"), orderBy("createdDate", "desc"), limit(size))
  }
  const docs = await getDocs(q)
  const rs = [];
  docs.forEach(item => {
    rs.push({...item.data(), id: item.id})
  })
  return {
    data: rs,
    isMore: rs.length === Number(size),
    lastItem: docs.docs[rs.length - 1]
  };
}

export async function getStudent(id) {
  const docRef = doc(db, "register", id);
  const rs = await getDoc(docRef);
  if (rs.exists()) {
    return rs.data()
  }
  throw new Error(`Not found any student with id ${id}`)
}

export function addStudent(data) {
  return addDoc(collection(db, "register"), data);
}

export async function deleteStudent(id){
  const docRef = doc(db, "register", id);
  return deleteDoc(docRef)
}
