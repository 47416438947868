import React from "react";
import './Wizard.scss';

export const STATE = {
  CURRENT: 'current',
  PENDING: 'pending',
}
export const STATUS = {
  DONE: 'done'
}

export function Wizard(props) {
  return (
    <div className="wizard wizard-2" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="false">
      {props.children}
    </div>
  );
}

export function WizardBody(props) {
  return (
    <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
      <div className="row">
        <div className="offset-xxl-0 col-xxl-12">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export function WizardBodyStep(props) {
  return (
    <div className="pb-5" data-wizard-type="step-content" data-wizard-state={props.state}>
      {props.children}
    </div>
  );
}

export function WizardIcon(props) {
  return (
    <div className="wizard-icon">
      {props.children}
    </div>
  );
}

export function WizardLabel(props) {

  return (
    <div className="wizard-label">
      <h3 className="wizard-title">{props.title}</h3>
      <div className="wizard-desc">{props.desc}</div>
    </div>
  );
}

export function WizardNav(props) {
  return (
    <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
      {props.steps
        .map((step, index) => <WizardNavStep onSelect={props.selectStep} index={index}
                                             key={step.id} {...step} state={props.currentStep === index ? STATE.CURRENT : ''}/>)}
    </div>
  );
}

export function WizardNavStep(props) {
  return (
    <div className="wizard-steps" onClick={() => props.onSelect(props.index)}>
      <div className="wizard-step" data-wizard-type="step" data-wizard-state={props.state}>
        <div className="wizard-wrapper">
          <WizardIcon children={props.icon}/>
          <WizardLabel title={props.title} desc={props.desc}/>
          {
            props.status === STATUS.DONE &&
            <div className="flex-fill align-items-center d-flex justify-content-end">
              <i className="fa fa-check text-success fa-fw fa-2x"/>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
