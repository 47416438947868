import React from "react";
import { Input, Label } from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";

export const InputRadio = ({ value, label, name, type, register, ...props}) => {
  const {onChange, onBlur, name: _name, ref} = register(name);
  return (
    <Label className={classNames('radio col-form-label', type ? `radio-${type}` : '', props.className)}>
      <Input
        value={value}
        type={'radio'}
        innerRef={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={_name}
        {...props} />
      <span></span>
      {label}
    </Label>
  );
}

InputRadio.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.any,
  type: PropTypes.oneOf([ 'primary', 'success', 'danger', 'warning' ])
};

export default InputRadio;
