import React from 'react';
import PropTypes from 'prop-types';
import {formatDateOnly, formatFirebaseDate} from "../../../../utils/date.util";
import {
  ANSWER,
  ANSWER_CSS,
  BUS_ROUTE,
  CLASS_LIST,
  GENDER,
  GRADE,
  KNOW_VIA_SOCIAL,
  OFTEN_USING_SWEET,
  STAY_WITH_LABEL,
  TV_SHOW
} from "../../../admin/student/constant";
import {getCountryByAlpha3} from "../../../../libs/api/country.api";
import _ from 'lodash';
import SubmitButton from "../../../../components/buttons/SubmitButton";

const Summary = ({onNext, data, ...props}) => {

  const formattedData = (data) => {

    const formatted = _.cloneDeep(data);

    delete formatted.summary;

    return formatted;
  };

  return (
    <>
      <div className="children-info" id="printEls">
        <h4>Thông tin trẻ</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Họ và Tên</td>
            <td className="value">{data.information.fullName}</td>
            <td className="key">Tên Thân Mật</td>
            <td className="value">{data.information.alias}</td>
          </tr>
          <tr>
            <td className="key">Địa Chỉ</td>
            <td className="value">{data.information.address}</td>
            <td className="key">Ngày sinh</td>
            <td className="value">{formatDateOnly(data.information.birthday)}</td>
          </tr>
          <tr>
            <td className="key">Giới tính</td>
            <td className="value">{GENDER[data.information.gender]}</td>
            <td className="key">Quốc tịch</td>
            <td className="value">{getCountryByAlpha3(data.information.country)?.name}</td>
          </tr>
          <tr>
            <td className="key">Đăng ký vào lớp</td>
            <td className="value">{CLASS_LIST[data.information.registerClass]}</td>
          </tr>
          <tr>
            <td className="key">Tuyến đường đưa đón buổi sáng</td>
            <td className="value">{BUS_ROUTE[data.information.busRouteFrom]}</td>
          </tr>
          <tr>
            <td className="key">Tuyến đường đưa đón buổi chiều</td>
            <td className="value">{BUS_ROUTE[data.information.busRouteTo]}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="children-info" id="printEls">
        <h4>Quá trình học của trẻ</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Quá trình học của trẻ từ 0 - 3 tuổi</td>
            <td className="value">{data.learning.childLearn03}</td>
          </tr>
          <tr>
            <td className="key">Quá trình học của trẻ từ 3 - 6 tuổi</td>
            <td className="value">{data.learning.childLearn36}</td>
          </tr>
          <tr>
            <td className="key">Quá trình học tiểu học của trẻ (nếu trẻ đã học ở trường khác)</td>
            <td className="value">{data.learning.childLearn67}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="children-info" id="printEls">
        <h4>Thông tin gia đình</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Tình trạng hôn nhân</td>
            <td className="value">{STAY_WITH_LABEL[data.family.stayWith]}</td>
          </tr>
          <tr>
            <td className="key">Số con trong gia đình</td>
            <td className="value">{data.family.numberOfChildren}</td>
          </tr>
          </tbody>
        </table>
        <table className="info table table-borderless">
          <caption>Thông tin của ba (bố)</caption>
          <tbody>
          <tr>
            <td className="key">Họ và Tên</td>
            <td className="value">{data.family.fatherFullName}</td>
            <td className="key">Ngày sinh</td>
            <td className="value">{formatDateOnly(data.family.fatherBirthday)}</td>
          </tr>
          <tr>
            <td className="key">Nghề nghiệp</td>
            <td className="value">{data.family.fatherJob}</td>
            <td className="key">Học vấn</td>
            <td className="value">{GRADE[data.family.fatherGrade]}</td>
          </tr>
          <tr>
            <td className="key">Nơi công tác hiện tại</td>
            <td className="value">{data.family.fatherCurrentJob}</td>
          </tr>
          <tr>
            <td className="key">Điện thoại di động</td>
            <td className="value">{data.family.fatherMobilePhone}</td>
            <td className="key">Điện thoại bàn</td>
            <td className="value">{data.family.fatherTablePhone}</td>
          </tr>
          <tr>
            <td className="key">Email</td>
            <td className="value">{data.family.fatherEmail}</td>
          </tr>
          </tbody>
        </table>
        <table className="info table table-borderless">
          <caption>Thông tin của mẹ</caption>
          <tbody>
          <tr>
            <td className="key">Họ và Tên</td>
            <td className="value">{data.family.motherFullName}</td>
            <td className="key">Ngày sinh</td>
            <td className="value">{formatDateOnly(data.family.motherBirthday)}</td>
          </tr>
          <tr>
            <td className="key">Nghề nghiệp</td>
            <td className="value">{data.family.motherJob}</td>
            <td className="key">Học vấn</td>
            <td className="value">{GRADE[data.family.motherGrade]}</td>
          </tr>
          <tr>
            <td className="key">Nơi công tác hiện tại</td>
            <td className="value">{data.family.motherCurrentJob}</td>
          </tr>
          <tr>
            <td className="key">Điện thoại di động</td>
            <td className="value">{data.family.motherMobilePhone}</td>
            <td className="key">Điện thoại bàn</td>
            <td className="value">{data.family.motherTablePhone}</td>
          </tr>
          <tr>
            <td className="key">Email</td>
            <td className="value">{data.family.motherEmail}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="children-info" id="printEls">
        <h4>Thông tin sức khoẻ của trẻ</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Quá trình mang thai và sinh em bé</td>
            <td className="value">{data.health.summaryBirthDay}</td>
          </tr>
          <tr>
            <td className="key">Độ tuổi mà bé biết bò</td>
            <td className="value">{data.health.crawlAge}</td>
            <td className="key">Độ tuổi mà bé biết ngồi</td>
            <td className="value">{data.health.sitAge}</td>
          </tr>
          <tr>
            <td className="key">Độ tuổi mà bé biết đi</td>
            <td className="value">{data.health.walkAge}</td>
            <td className="key">Độ tuổi mà bé biết nói</td>
            <td className="value">{data.health.speakAge}</td>
          </tr>
          <tr>
            <td className="key">Bé đã ngủ trên giường một mình chưa ?</td>
            <td className="value">{ANSWER[data.health.sleepAlone]}</td>
          </tr>
          <tr>
            <td className="key">Những người thân trong gia đình thường xuyên tiếp xúc với trẻ</td>
            <td className="value">
              <ul className="list-inline">
                {data.health.families?.map(f => <li key={f} className="list-inline-item"><span
                  className="badge badge-info mr-1">{f}</span></li>)}
              </ul>
            </td>
          </tr>
          <tr>
            <td className="key">Bé có dị ứng gì không, cụ thể ?</td>
            <td className="value">{data.health.allergy}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="children-info" id="printEls">
        <h4>Thông tin bệnh lý của trẻ</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Suy dinh dưỡng</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.malnutrition]}>{ANSWER[data.sickness.malnutrition]}</span>
            </td>
            {
              data.sickness.malnutrition ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.malnutritionDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Sốc mẫn cảm, sốc phản vệ với thuốc</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.medicineShock]}>{ANSWER[data.sickness.medicineShock]}</span>
            </td>
            {
              data.sickness.medicineShock ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.medicineShockDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Yêu cầu ăn kiêng đặc biệt</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.specialDiet]}>{ANSWER[data.sickness.specialDiet]}</span>
            </td>
            {
              data.sickness.specialDiet ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.specialDietDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các vấn đề về tim mạch</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.heartSick]}>{ANSWER[data.sickness.heartSick]}</span>
            </td>
            {
              data.sickness.heartSick ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.heartSickDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các vấn đề về đường hô hấp, hen suyển</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.respiratory]}>{ANSWER[data.sickness.respiratory]}</span>
            </td>
            {
              data.sickness.respiratory ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.respiratoryDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các vấn đề về dị ứng (liên quan đến thuốc men)</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.medicineAllergy]}>{ANSWER[data.sickness.medicineAllergy]}</span>
            </td>
            {
              data.sickness.medicineAllergy ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.medicineAllergyDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các vấn đề về lưng xương khớp</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.backBone]}>{ANSWER[data.sickness.backBone]}</span>
            </td>
            {
              data.sickness.backBone ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.backBoneDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Bệnh Celiac (một bệnh tiêu hoá gây tổn thương ruột non và gây cản trở sự hấp thụ các
              chất dinh dưỡng từ thức ăn)
            </td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.celiac]}>{ANSWER[data.sickness.celiac]}</span>
            </td>
            {
              data.sickness.celiac ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.celiacDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Hội chứng DOWN</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.down]}>{ANSWER[data.sickness.down]}</span>
            </td>
            {
              data.sickness.down ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.downDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Động kinh</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.epileptic]}>{ANSWER[data.sickness.epileptic]}</span>
            </td>
            {
              data.sickness.epileptic ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.epilepticDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Tiểu đường</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.diabetes]}>{ANSWER[data.sickness.diabetes]}</span>
            </td>
            {
              data.sickness.diabetes ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.diabetesDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">HIV, viêm gan A, B, C</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.hiv]}>{ANSWER[data.sickness.hiv]}</span>
            </td>
            {
              data.sickness.hiv ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.hivDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">ADD/HDHD ..., (tăng động, giảm chú ý)</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.addHdhd]}>{ANSWER[data.sickness.addHdhd]}</span>
            </td>
            {
              data.sickness.addHdhd ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.addHdhdDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Phản ứng thuốc</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.drugReaction]}>{ANSWER[data.sickness.drugReaction]}</span>
            </td>
            {
              data.sickness.drugReaction ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.drugReactionDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Bệnh về tiêu hoá</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.digestive]}>{ANSWER[data.sickness.digestive]}</span>
            </td>
            {
              data.sickness.digestive ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.digestiveDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các bệnh ngoài da truyền nhiễm</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.skin]}>{ANSWER[data.sickness.skin]}</span>
            </td>
            {
              data.sickness.skin ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.skinDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Nhiễm một số loại giun sán</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.helminths]}>{ANSWER[data.sickness.helminths]}</span>
            </td>
            {
              data.sickness.helminths ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.helminthsDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Các bệnh lý mắc phải gần đây</td>
            <td className="value">
                <span
                  className={ANSWER_CSS[data.sickness.recentlySickness]}>{ANSWER[data.sickness.recentlySickness]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Con bạn đang ở trong tình trạng <strong className="text-danger">cảnh báo y tế</strong>
            </td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.sickWarning]}>{ANSWER[data.sickness.sickWarning]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Trẻ có đang sử dụng lâu dài bất cứ loại thuốc nào không?</td>
            <td className="value">
                <span
                  className={ANSWER_CSS[data.sickness.longTermMedicine]}>{ANSWER[data.sickness.longTermMedicine]}</span>
            </td>
            {
              data.sickness.longTermMedicine ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.longTermMedicineDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Thông tin khác</td>
            <td className="value">
              <span className={ANSWER_CSS[data.sickness.other]}>{ANSWER[data.sickness.other]}</span>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <div className="children-info" id="printEls">
        <h4>Thông tin trẻ</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Mô tả cách bé thức dậy (mơ màng, càu nhàu, hào hứng)</td>
            <td className="value">
              {data.homeActivity.wakeupFeeling}
            </td>
          </tr>
          <tr>
            <td className="key">Bé đi ngủ vào lúc</td>
            <td className="value">
              {data.homeActivity.sleepTime}
            </td>
            <td className="key">Thức dậy vào lúc</td>
            <td className="value">
              {data.homeActivity.wakeupTime}
            </td>
          </tr>
          <tr>
            <td className="key">Cách phụ huynh xử lý cảm xúc bất thường của trẻ tại nhà (khi bé đòi hỏi, quấy khóc, vòi
              vĩnh, ...)
            </td>
            <td className="value"> {data.homeActivity.parentReactionAtMistake} </td>
          </tr>
          <tr>
            <td className="key">Một số thói quen khác của trẻ (cắn móng tay, xoắn tóc, mút tay ...)</td>
            <td className="value"> {data.homeActivity.babyHabit} </td>
          </tr>
          <tr>
            <td className="key">Một số nhu cầu đặt biệt, hoặc nổi sợ của bé</td>
            <td className="value"> {data.homeActivity.specialChildrenNeed} </td>
          </tr>
          <tr>
            <td className="key">Các hoạt động khi bé vui chơi ngoài trời</td>
            <td className="value"> {data.homeActivity.outdoorActivity} </td>
          </tr>
          <tr>
            <td className="key">Bé có hay ăn đồ ngọt không ?</td>
            <td className="value">
                <span
                  className={ANSWER_CSS[data.homeActivity.oftenUsingSweet]}>{ANSWER[data.homeActivity.oftenUsingSweet]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Bé có đang đi học các môn năng khiếu hoặc chương trình ngoại khoá (ngoại ngữ, nhạc, hội
              hoạ, ...)?
            </td>
            <td className="value">
                <span
                  className={ANSWER_CSS[data.homeActivity.extraSkillClass]}>{ANSWER[data.homeActivity.extraSkillClass]}</span>
            </td>
            {
              data.sickness.extraSkillClass ?
                <>
                  <td className="key">Chi tiết</td>
                  <td className="value">
                    {data.sickness.extraSkillClassDetail}
                  </td>
                </> : null
            }
          </tr>
          <tr>
            <td className="key">Trẻ có được phép xem tivi, thiết bị điện tử ở nhà không ?</td>
            <td className="value">
                <span
                  className={ANSWER_CSS[data.homeActivity.deviceAllowance]}>{ANSWER[data.homeActivity.deviceAllowance]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Tần suất xem tivi, thiết bị điện tử của trẻ</td>
            <td className="value">
              <span className="badge badge-danger">{OFTEN_USING_SWEET[data.homeActivity.watchingTVFrequency]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Chương trình bé hay xem trên tivi, thiết bị điện tử ?</td>
            <td className="value">
              <span className="badge badge-warning">{TV_SHOW[data.homeActivity.tvShow]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Khi cáu giận bé thường làm gì ?</td>
            <td className="value">
              {data.homeActivity.angryReaction}
            </td>
          </tr>
          <tr>
            <td className="key">Mô tả các hoạt động thường xuyên của trẻ</td>
            <td className="value">
              {data.homeActivity.usualPlaying}
            </td>
          </tr>
          </tbody>
        </table>
        <div/>
      </div>
      <div className="children-info" id="printEls">
        <h4>Một số thông tin khác</h4>
        <table className="info table table-borderless">
          <tbody>
          <tr>
            <td className="key">Phụ huynh mong muốn điều gì khi gởi con đến trường ?</td>
            <td className="value">
              {data.other.parentNeedAboutChildren}
            </td>
          </tr>
          <tr>
            <td className="key">Những chủ đề giáo dục mà phụ huynh quan tâm</td>
            <td className="value">
              {data.other.educationSubjects}
            </td>
          </tr>
          <tr>
            <td className="key">Phụ huynh hiểu thế nào về phương pháp giáo dục Waldorf-Steiner</td>
            <td className="value">
              {data.other.understandingWaldorf}
            </td>
          </tr>
          <tr>
            <td className="key">Phụ huynh có muốn tham gia các hội thảo về giáo dục do nhà trường tổ chức ?</td>
            <td className="value">
              <span className={ANSWER_CSS[data.other.joinConference]}>{ANSWER[data.other.joinConference]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Phụ huynh biết về trường qua</td>
            <td className="value">
              <span className="badge badge-info">{KNOW_VIA_SOCIAL[data.other.knownViaSocial]}</span>
            </td>
          </tr>
          <tr>
            <td className="key">Phụ huynh có thể tình nguyện đóng góp thời gian và chuyên môn của mình vào các hoạt động
              của nhà trường
            </td>
            <td className="value">
              <ul className="list-inline">
                {
                  data.other.schoolVolunteer?.map((val, index) => <li className="list-inline-item m-1"
                                                                      key={`schoolVolunteer${index}`}><span
                    className="badge badge-info">{val?.label || val}</span></li>)
                }
              </ul>

            </td>
          </tr>
          <tr>
            <td className="key">Một số thông tin khác phụ huynh muốn chia sẻ thêm</td>
            <td className="value">
              <span className="badge badge-info">{data.other.parentSharing}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            props.onPrev && <button type="button" onClick={props.onPrev}
                                    className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <SubmitButton
            type="button"
            onClick={() => {
              onNext(formattedData(data));
            }}
            isLoading={props.isLoading}
            className={`font-weight-bolder text-uppercase px-9 py-4`}
            data-wizard-type="action-next">Hoàn Tất
          </SubmitButton>
        </div>
      </div>
    </>
  );
};

Summary.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  isLoading: PropTypes.bool
};

export default Summary;
