import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {STATUS, Wizard, WizardBody, WizardNav} from '../../../components/Wizard/Wizard';
import {Card, CardBody} from '../../../../_metronic/_partials/controls';
import {addStudent} from '../../../libs/service/Firebase';
import {API_STATE, useApi} from '../../../libs/hooks/useApi';
import {Link} from 'react-router-dom';
import FormChildren from "./forms/FormChildren";
import FormFamily from "./forms/FormFamily";
import FormLearning from "./forms/FormLearning";
import FormChildrenHealth from "./forms/FormChildenHealth";
import FormChildSickness from "./forms/FormChildSickness";
import FormHomeActivity from "./forms/FormHomeActivity";
import FormOtherInformation from "./forms/FormOtherInformation";
import Summary from "./forms/Summary";
import './RegisterChildren.scss';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {toast} from "react-toastify";

const STEPS = [
  {
    id: "information",
    icon: <i className="fas fa-child fa-fw"/>,
    title: 'Thông Tin Về Trẻ',
    desc: '',
    status: '',
    element: FormChildren
  },
  {
    id: "learning",
    icon: <i className="fas fa-school fa-fw"/>,
    title: 'Quá trình đi học của trẻ',
    desc: '',
    status: '',
    element: FormLearning
  },
  {
    id: "family",
    icon: <i className="fas fa-users fa-fw"/>,
    title: 'Thông Tin Ga Đình',
    desc: '',
    status: '',
    element: FormFamily
  },
  {
    id: "health",
    icon: <i className="fas fa-heartbeat fa-fw"/>,
    title: 'Thông tin sức khoẻ của trẻ',
    desc: '',
    status: '',
    element: FormChildrenHealth
  },
  {
    id: "sickness",
    icon: <i className="fas fa-file-medical fa-fw"/>,
    title: 'Thông tin bệnh lý',
    desc: '',
    status: '',
    element: FormChildSickness
  },
  {
    id: "homeActivity",
    icon: <i className="fas fa-home fa-fw"/>,
    title: 'Nhịp điệu sinh hoạt tại nhà',
    desc: '',
    status: '',
    data: {},
    element: FormHomeActivity
  },
  {
    id: "other",
    icon: <i className="fa fa-info fa-fw"/>,
    title: 'Một số thông tin khác',
    desc: '',
    status: '',
    element: FormOtherInformation
  },
  {
    id: "summary",
    icon: <i className="fa fa-fw fa-eye"/>,
    title: 'Xem lại thông tin và hoàn tất',
    desc: '',
    status: '',
    element: Summary
  },
];

function RegisterChildren(props) {
  const [steps, setSteps] = useState(STEPS);
  const {state, exec} = useApi(addStudent)
  const [currentStep, setCurrentStep] = useState(-1);

  const selectStep = (index) => {
    if (steps[index].status === STATUS.DONE) {
      setCurrentStep(index);
    }
  }

  const next = useCallback((data) => {
    console.log('onNext', data)
    window.scrollTo({top: 0, behavior: 'smooth'});
    setSteps((prevSteps) => {
      prevSteps[currentStep].status = STATUS.DONE;
      prevSteps[currentStep].data = data;
      return [...prevSteps]
    })
    if (currentStep === STEPS.length - 1) {
      onSubmit(data);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  }, [currentStep])

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  }

  const onSubmit = (data) => {
    console.log('onSubmit', data);
    exec({
      createdDate: new Date(),
      ...data
    });
  }

  const finalData = () => {
    const r = {};
    STEPS.forEach(t => r[t.id] = t.data);
    return r;
  }

  useEffect(() => {
    if (state.state === API_STATE.SUCCESS) {
      setCurrentStep((prev) => prev + 1);
    } else if (state.state === API_STATE.FAIL) {
      toast.error(state.errors.map(t => t.message).join('\n'))
    }
  }, [state.state])

  const wizardBody = useMemo(() => {
    if (currentStep >= 0 && currentStep < STEPS.length) {
      return React.createElement(STEPS[currentStep].element, {
        data: STEPS[currentStep].id === 'summary' ? finalData() : steps[currentStep].data,
        onNext: next,
        onPrev: currentStep > 0 ? prev : null,
        isLoading: state.isLoading,
        onSubmit: onSubmit
      })
    }
    return null
  }, [currentStep, state, next, prev])

  const mainBody = useMemo(() => {
    switch (currentStep) {
      case -1:
        return <div className="" style={{margin: "30px auto"}}>
          <img
            src={toAbsoluteUrl('/media/logos/full_harmony.png')}
            className="d-block ml-auto mr-auto mb-5" alt="Logo"
            style={{width: '400px'}}/>
          <h2 className="text-primary mb-4">Xin chào Quý Phụ Huynh,</h2>
          <p> Mỗi trẻ em là một thiên thần mà mỗi người
            chúng ta luôn trăn trở trong việc chăm sóc, trải nghiệm cùng với thiên thần của chúng ta,
            và nhà trường cũng mong muốn mang lại nhiều trải nghiệm đa dạng phù hợp với từng thiên thần.</p>
          <p>Vì vậy chúng mình rất mong Quý
            phụ huynh dành chút thời gian để điền đầy đủ thông tin theo mẫu sau để nhà trường có thể thêm thông tin
            và đồng hành cùng gia đình trong việc giáo dưỡng các thiên thần. Các thông tin được chia sẻ giữa
            phụ
            huynh và nhà trường sẽ được đảm bảo riêng tư và không bên thứ ba nào biết được.
          </p>
          <p className="text-danger">Mọi việc công khai thông tin
            mà không được sự đồng ý của bố mẹ, nhà trường sẽ chịu mọi trách nhiệm.
          </p>
          <p><strong>Trân trọng cảm ơn Quý Phụ Huynh !!! </strong></p>
          <button onClick={() => setCurrentStep(0)}
                  className="btn btn-outline-primary btn-lg text-uppercase font-size-lg"
                  type="button"> Bắt Đầu
          </button>
        </div>
      case STEPS.length:
        return <div style={{minHeight: '500px'}} className="d-flex align-items-center">
          <div className="text-center m-auto">
            <h1 className="text-primary">Xin chân thành cảm ơn Quý Phụ Huynh !!!</h1>
            <p className="lead"> Xin cảm ơn bố, mẹ đã đăng ký thông tin của trẻ. Nhà trường sẽ liên hệ lại với bố mẹ
              để sắp xếp thời gian hẹn gặp và phỏng vấn. </p>
            <Link to="/" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mt-5"
                  data-wizard-type="action-next">Trang Chủ</Link>
          </div>
        </div>
      default:
        return <Wizard>
          <WizardNav selectStep={selectStep} steps={steps} currentStep={currentStep}/>
          <WizardBody>
            {wizardBody}
          </WizardBody>
        </Wizard>
    }
  }, [currentStep, selectStep, steps, wizardBody, setCurrentStep])

  return (
    <div className="container mt-10 register-children">
      <Card>
        <CardBody>
          {mainBody}
        </CardBody>
      </Card>
    </div>
  );
}

RegisterChildren.propTypes = {};

export default RegisterChildren;
