import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import AboutUs from "./modules/public/AboutUs";
import MainPublicPage from "./modules/public/MainPublicPage";
import PublicHeader from "./modules/public/PublicHeader";
import PublicFooter from "./modules/public/PublicFooter";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {Logout} from "./modules/Auth";
import './modules/public/public.scss'
import RegisterChildren from './modules/public/RegisterChildren/RegisterChildren';

const PublicPage = props => {
  return (
    <>
      <PublicHeader/>
      <Switch>
        <Route path="/about-us" component={AboutUs}/>
        <Route path="/logout" component={Logout}/>
        <Route path="/" exact component={MainPublicPage}/>
        <Route path="/error" component={ErrorsPage}/>
        <Route path="/dang-ky-tre" component={RegisterChildren}/>
        <Redirect to="error/error-v1" />
      </Switch>
      <PublicFooter/>
    </>
  );
};

PublicPage.propTypes = {};

export default PublicPage;
