import React, { useCallback } from 'react';
import {INITIAL_VALUE} from "../constant";
import PropTypes from "prop-types";
import { useForm } from 'react-hook-form';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import InputRadio from '../../../../components/input/InputRadio';
import { Col, FormGroup } from 'reactstrap';
import { TV_SHOW } from '../../../admin/student/constant';

const FormHomeActivity = ({ onNext, onPrev, ...props }) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: props.data || INITIAL_VALUE.formHomeActivity
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  const extraSkillClass = watch('extraSkillClass');

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="wakeupFeeling" type="textarea" register={register} label="Mô tả cách bé thức dậy (mơ màng, càu nhàu, hào hứng)" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <FormGroupInput name="sleepTime" type="text" register={register} label="Bé đi ngủ vào lúc" />
        </div>
        <div className="col-md-6">
          <FormGroupInput name="wakeupTime" type="text" register={register} label="Thức dậy vào lúc" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="parentReactionAtMistake" type="textarea" register={register} label="Cách phụ huynh xử lý cảm xúc bất thường của trẻ tại nhà (khi bé đòiCách phụ huynh xử lý cảm xúc bất thường của trẻ tại nhà (khi bé đòi hỏi, quấy khóc, vòi vĩnh, ..." />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroupInput rows={3} name="babyHabit" type="textarea" register={register} label="Một số thói quen khác của trẻ (cắn móng tay, xoắn tóc, mút tay ...)" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
        <FormGroupInput rows={3} name="specialChildrenNeed" type="textarea" register={register} label="Một số nhu cầu đặc biệt, hoặc nổi sợ của bé" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
        <FormGroupInput rows={3} name="outdoorActivity" type="textarea" register={register} label="Các hoạt động khi bé vui chơi ngoài trời" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <FormGroup row>
            <Col md={4}>
              <label className="form-label col-form-label">{'Bé có hay ăn đồ ngọt không ?'}</label>
            </Col>
            <Col md={8}>
              <div className="radio-inline">
                <InputRadio value={'0'} label={'Không'} name={'oftenUsingSweet'} register={register} />
                <InputRadio type="warning" value={'1'} label={'Thỉnh Thoảng'} name={'oftenUsingSweet'} register={register} />
                <InputRadio type="danger" value={'2'} label={'Có'} name={'oftenUsingSweet'} register={register} />
              </div>
            </Col>
          </FormGroup>
        </div>
      </div>
      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Bé có đang đi học các môn năng khiếu hoặc chương trình ngoại khoá (ngoại ngữ, nhạc, hội hoạ, ...) ?  
          </label>
        </Col>
        <Col md={3}>
          <div className="radio-inline">
            <InputRadio value={'0'} label={'Không'} name={'extraSkillClass'} register={register} />
            <InputRadio value={'1'} label={'Có'} name={'extraSkillClass'} register={register} />
          </div>
        </Col>
        {
          (Number(extraSkillClass) === 1) &&
          <Col md={5}>
            <input
              type="text"
              className={`form-control`}
              placeholder='Chi tiết'
              name={'extraSkillClassDetail'}
              {...register('extraSkillClassDetail')}
            />
          </Col>
        }
      </FormGroup>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Trẻ có được phép xem tivi, thiết bị điện tử ở nhà không ? 
          </label>
        </Col>
        <Col md={8}>
          <div className="radio-inline">
            <InputRadio value={'0'} label={'Không'} name={'deviceAllowance'} register={register} />
            <InputRadio value={'1'} label={'Có'} name={'deviceAllowance'} register={register} />
          </div>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Tần suất xem tivi, thiết bị điện tử của trẻ ?
          </label>
        </Col>
        <Col md={8}>
          <div className="radio-inline">
            <InputRadio value={'0'} label={'Mỗi tối'} name={'watchingTVFrequency'} register={register} />
            <InputRadio value={'1'} label={'Thỉnh thoảng'} name={'watchingTVFrequency'} register={register} />
          </div>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col md={4}>
          <label className="form-label col-form-label">
            Chương trình bé hay xem trên tivi, thiết bị điện tử ?
          </label>
        </Col>
        <Col md={8}>
          <div className="radio-inline">
            {
              TV_SHOW.map((t, i) => <InputRadio key={i} value={`${i}`} label={t} name={'tvShow'} register={register} />)
            }
          </div>
        </Col>
      </FormGroup>

      <div className="row">
        <div className="col-md-12">
        <FormGroupInput rows={3} name="angryReaction" type="textarea" register={register} label="Khi cáu giận bé thường làm gì ?" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
        <FormGroupInput rows={3} name="usualPlaying" type="textarea" register={register} label="Mô tả các hoạt động thường xuyên của trẻ (các hoạt động lặp lại hàng tuần hoặc
            hằng ngày, như học võ, chơi game, ...) ?" />
        </div>
      </div>
      
      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
                                    className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  );
};

FormHomeActivity.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormHomeActivity;
