import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import {ADMIN_URL} from "./constant";
import {STUDENT_URL} from "./modules/admin/student/constant";

const StudentPage = lazy(() =>
  import("./modules/admin/student/index")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={ADMIN_URL} to={`${ADMIN_URL}/dashboard`} />
        }
        <ContentRoute path={`${ADMIN_URL}/dashboard`} component={DashboardPage} />
        <ContentRoute path={`${STUDENT_URL}`} component={StudentPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
