import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { GRADE, STAY_WITH, STAY_WITH_LABEL } from "../../../admin/student/constant";
import { useFormik } from "formik";
import { INITIAL_VALUE } from "../constant";
import DateSelect from "../../../../components/date/DateSelect";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroup from '../../../../components/form/FormGroup';
import FormErrorMessage from '../../../../components/form/FormErrorMessage';
import classNames from 'classnames';
import FormGroupInput from '../../../../components/form/FormGroupInput';
import { lastDate, TIME_UNIT } from '../../../../utils/date.util';

const FormFamilySchema = Yup.object().shape({
  stayWith: Yup.number().required(),
  motherEmail: Yup.string().email('Định dạng email không hợp lệ.'),
  fatherEmail: Yup.string().email('Định dạng email không hợp lệ.'),
  fatherFullName: Yup.string()
    .when('stayWith', {
      is: val => [STAY_WITH.BOTH, STAY_WITH.FATHER].includes(Number(val)),
      then: Yup.string().required('Tên của bố là bắt buộc.'),
      otherwise: Yup.string()
    }),
  fatherMobilePhone: Yup.string()
    .when('stayWith', {
      is: val => [STAY_WITH.BOTH, STAY_WITH.FATHER].includes(Number(val)),
      then: Yup.string().required('Số điện thoại là bắt buộc.'),
      otherwise: Yup.string()
    }),
  motherFullName: Yup.string()
    .when('stayWith', {
      is: val => [STAY_WITH.BOTH, STAY_WITH.MOTHER].includes(Number(val)),
      then: Yup.string().required('Tên của mẹ là bắt buộc.'),
      otherwise: Yup.string()
    }),
  motherMobilePhone: Yup.string()
    .when('stayWith', {
      is: val => [STAY_WITH.BOTH, STAY_WITH.MOTHER].includes(Number(val)),
      then: Yup.string().required('Số điện thoại là bắt buộc'),
      otherwise: Yup.string()
    }),
});

const FormFamily = ({ onNext, onPrev, ...props }) => {

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
    trigger
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(FormFamilySchema),
    defaultValues: props.data || INITIAL_VALUE.formFamily
  });

  const onSubmit = useCallback(
    handleSubmit(formData => onNext(formData)),
    [handleSubmit, onNext]
  );

  const formik = useFormik({
    initialValues: props.data || INITIAL_VALUE.formFamily,
    validationSchema: FormFamilySchema,
    onSubmit: (values) => {
      props.onNext(values);
    }
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const watchStayWith = Number(watch("stayWith"));

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-12">
          <FormGroup label="Tình trạng hôn nhân của ba (bố) mẹ" fieldRequired={true} forId="stayWith">
            <select
              className={classNames(`form-control`, { 'is-invalid': !!errors.stayWith })}
              id="stayWith"
              {...register("stayWith")}>
              <option value={STAY_WITH.BOTH}>{STAY_WITH_LABEL[STAY_WITH.BOTH]}</option>
              <option value={STAY_WITH.FATHER}>{STAY_WITH_LABEL[STAY_WITH.FATHER]}</option>
              <option value={STAY_WITH.MOTHER}>{STAY_WITH_LABEL[STAY_WITH.MOTHER]}</option>
            </select>
            <FormErrorMessage error={errors.stayWith} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <FormGroupInput name="numberOfChildren" placeholder={"Số con hiện tại"}
                          error={errors.numberOfChildren} label="Số con hiện tại"
                          type={'number'} register={register} />
        </div>
      </div>
      <div className="row">
      {
        [STAY_WITH.BOTH, STAY_WITH.FATHER].includes(watchStayWith) &&
        <div className={classNames('p-5', {'col-md-12': watchStayWith === STAY_WITH.FATHER, 'col-md-6': watchStayWith === STAY_WITH.BOTH})}>
          <h4 className="mb-10 font-weight-bold text-dark">Thông Tin Của Bố</h4>

          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="fatherFullName" placeholder={"Họ và Tên"}
                error={errors.fatherFullName} label="Họ và Tên" fieldRequired={true}
                type={'text'} register={register} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormGroup label="Trình độ học vấn" fieldRequired={false} forId="fatherGrade">
                <select
                  className={classNames(`form-control`, { 'is-invalid': !!errors.fatherGrade })}
                  id="fatherGrade"
                  {...register("fatherGrade")}>
                  <option value={''}>- Chọn trình độ học vấn -</option>
                  {GRADE.map((t, index) => <option value={index} key={`fagrade${index}`}>{t}</option>)}
                </select>
                <FormErrorMessage error={errors.fatherGrade} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup fieldRequired={false} label="Ngày sinh" forId="fatherBirthday">
                <Controller
                  fieldRequired={false}
                  name="fatherBirthday"
                  control={control}
                  render={({ field: { value, onChange, name, onBlur }, fieldState: { invalid, error } }) => (
                    <div className={classNames({ 'is-invalid': invalid })}>
                      <DateSelect
                        value={value}
                        name={name}
                        placeholder="Ngày Sinh"
                        invalid={invalid}
                        onChange={onChange}
                        onBlur={onBlur}
                        openToDate={lastDate(18, TIME_UNIT.YEAR)}
                      />
                    </div>
                  )}
                />
                <FormErrorMessage error={errors.fatherBirthday} />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="fatherJob" placeholder={"Nghề Nghiệp"}
                              error={errors.fatherJob} label="Nghề Nghiệp"
                              type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="fatherCurrentJob" placeholder={"Nơi công tác hiện tại"}
                error={errors.fatherCurrentJob} label="Nơi công tác hiện tại" fieldRequired={false}
                type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormGroupInput fieldRequired={true} name="fatherMobilePhone" placeholder={"Điện thoại di động"}
                error={errors.fatherMobilePhone} label="Điện thoại di động"
                type={'text'} register={register} />
            </div>
            <div className="col-md-6">
              <FormGroupInput name="fatherTablePhone" placeholder={"Điện thoại bàn"}
                error={errors.fatherTablePhone} label="Điện thoại bàn"
                type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="fatherEmail" placeholder={"Email"}
                error={errors.fatherEmail} label="Email"
                type={'text'} register={register} />
            </div>
          </div>
        </div>
      }

      {
        [STAY_WITH.BOTH, STAY_WITH.MOTHER].includes(watchStayWith) &&
        <div className={classNames('p-5', {'col-md-12': watchStayWith === STAY_WITH.MOTHER, 'col-md-6 border-left border-primary': watchStayWith === STAY_WITH.BOTH})}>
          <h4 className="mb-10 font-weight-bold text-dark">Thông Tin Của Mẹ</h4>

          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="motherFullName" placeholder={"Họ và Tên"}
                error={errors.motherFullName} label="Họ và Tên" fieldRequired={true}
                type={'text'} register={register} />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <FormGroup label="Trình độ học vấn" fieldRequired={false} forId="motherGrade">
                <select
                  className={classNames(`form-control`, { 'is-invalid': !!errors.motherGrade })}
                  id="motherGrade"
                  {...register("motherGrade")}>
                  <option value={''}>- Chọn trình độ học vấn -</option>
                  {GRADE.map((t, index) => <option value={index} key={`mograde${index}`}>{t}</option>)}
                </select>
                <FormErrorMessage error={errors.motherGrade} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup fieldRequired={false} label="Ngày sinh" forId="motherBirthday">
                <Controller
                  name="motherBirthday"
                  control={control}
                  render={({ field: { value, onChange, name, onBlur }, fieldState: { invalid, error } }) => (
                    <div className={classNames({ 'is-invalid': invalid })}>
                      <DateSelect
                        value={value}
                        name={name}
                        placeholder="Ngày Sinh"
                        invalid={invalid}
                        onChange={onChange}
                        onBlur={onBlur}
                        openToDate={lastDate(18, TIME_UNIT.YEAR)}
                      />
                    </div>
                  )}
                />
                <FormErrorMessage error={errors.motherBirthday} />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="motherJob" placeholder={"Nghề Nghiệp"}
                              error={errors.motherJob} label="Nghề Nghiệp"
                              type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="motherCurrentJob" placeholder={"Nơi công tác hiện tại"}
                error={errors.motherCurrentJob} label="Nơi công tác hiện tại" fieldRequired={false}
                type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormGroupInput fieldRequired={true} name="motherMobilePhone" placeholder={"Điện thoại di động"}
                error={errors.motherMobilePhone} label="Điện thoại di động"
                type={'text'} register={register} />
            </div>
            <div className="col-md-6">
              <FormGroupInput name="motherTablePhone" placeholder={"Điện thoại bàn"}
                error={errors.motherTablePhone} label="Điện thoại bàn"
                type={'text'} register={register} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormGroupInput name="motherEmail" placeholder={"Email"}
                error={errors.motherEmail} label="Email"
                type={'text'} register={register} />
            </div>
          </div>
        </div>
      }
      </div>
      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          {
            onPrev && <button type="button" onClick={onPrev}
              className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4">Quay
              Lại</button>
          }
        </div>
        <div>
          <button type="submit" className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-next">Tiếp Theo
          </button>
        </div>
      </div>
    </form>
  );
};

FormFamily.propTypes = {
  data: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default FormFamily;
